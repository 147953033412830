'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
var ActivityEntry = /** @class */function () {
    function ActivityEntry() {
        this.init();
    }
    ActivityEntry.prototype.init = function () {
        this.closeActivityEntry();
    };
    // 关闭活动页悬浮入口
    ActivityEntry.prototype.closeActivityEntry = function () {
        var hasActivityEntry = document.body.contains(document.getElementsByClassName('goto-activity-box')[0]);
        if (hasActivityEntry) {
            var closeActivityBtn = document.querySelector('.close-activity');
            closeActivityBtn.addEventListener('click', function (e) {
                e.preventDefault();
                var ActivityEntryBox = document.querySelector('.goto-activity-box');
                ActivityEntryBox.classList.add('hide');
            });
        }
    };
    return ActivityEntry;
}();
exports.default = ActivityEntry;